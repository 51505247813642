// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const GOOGLE = document.querySelector("#google_translate_element");
const INPUTS = document.querySelectorAll(".language-picker__input");

const INIT = (google_te_combo = null) => {
    INPUTS.forEach((input) => {
        input.addEventListener("change", () => {
            if (input.value !== "") {
                google_te_combo.value = input.value;

                if ("createEvent" in document) {
                    const EVT = document.createEvent("HTMLEvents");

                    EVT.initEvent("change", false, true);
                    google_te_combo.dispatchEvent(EVT);
                } else {
                    google_te_combo.fireEvent("onchange");
                }
            }
        });
    });
};

const OBSERVER = new MutationObserver(() => {
    const GOOGLE_TE_COMBO = GOOGLE.querySelector(".goog-te-combo");

    if (GOOGLE_TE_COMBO) {
        INIT(GOOGLE_TE_COMBO);
        OBSERVER.disconnect();
    }
});

OBSERVER.observe(GOOGLE, {
    childList: true,
    subtree: true
});
