// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

const HERO = document.querySelector(".swiper-container--hero");

// init swiper
if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (HERO, {
        modules: [Autoplay, EffectFade, Navigation, Pagination],
        autoplay: {
            delay: 15000,
        },
        loop: true,
        navigation: {
            nextEl: HERO.querySelector(".swiper-button--next"),
            prevEl: HERO.querySelector(".swiper-button--prev"),
        },
        pagination: {
            el: HERO.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 150,
    });
}

const SLIDESHOWS = document.querySelectorAll(".slideshow-block");

// init swiper
if (SLIDESHOWS && SLIDESHOWS.length > 0) {
    SLIDESHOWS.forEach(slideshow => {
        const inner_slideshow = slideshow.querySelector(".swiper-container--slideshow");
        if (inner_slideshow.querySelectorAll(".swiper-slide").length > 1) {
            new Swiper (inner_slideshow, {
                modules: [Autoplay, Navigation, Pagination],
                autoplay: {
                    delay: 7500,
                },
                slidesPerView: 2,
                spaceBetween: 20,
                loop: true,
                navigation: {
                    nextEl: slideshow.querySelector(".swiper-button--next"),
                    prevEl: slideshow.querySelector(".swiper-button--prev"),
                },
                speed: 150,
                breakpoints: {
                    1400: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                },
            });
        }
    });
}
